<template>
  <Card
    :title="label"
    class="finn-stage"
    theme="flat"
    :class="[{ 'finn-stage--hide-first': hideFirst }, 'finn-stage--' + state]"
  >
    <DataTable
      v-if="value.length"
      theme="border"
      :selected.sync="selected"
      :value="value"
      :isDisabled="isDisabled"
      :columns="[{ id: 'content', label: 'Velg alle' }]"
    >
      <template #content="{ item }">
        <div class="finn-stage__item">
          <component
            :is="getItemState(item).is"
            v-bind="getItemState(item)"
            v-tooltip.right="getTooltip(item)"
            class="finn-stage__item-content"
          >
            <span>{{ item.name }}</span>
            <FontAwesomeIcon v-if="getItemIcon(item)" v-bind="getItemIcon(item)" />
            <FontAwesomeIcon v-if="item.url" :icon="['fal', 'external-link']" size="sm" />
          </component>

          <div class="finn-stage__item-actions">
            <ButtonComponent
              v-if="!item.canPreview && item.statistics"
              label="Statistikk"
              tag="a"
              target="_blank"
              :href="item.statistics"
            />
          </div>
        </div>
      </template>
    </DataTable>

    <div v-if="!value.length" class="finn-stage__placeholder">
      <FontAwesomeIcon v-if="state !== 'unpublished'" :icon="['fal', 'reply']" size="lg" />
      <template v-if="state === 'preview'">
        <p>Så snart du <b>forhåndsviser</b> annonser vil de dukke opp her</p>
        <p>
          Velg fra listen til venstre og trykk på "Forhåndsvis
          <FontAwesomeIcon :icon="['fal', 'angle-right']" size="lg" /> knappen"
        </p>
      </template>
      <template v-if="state === 'published'">
        <p>Så snart du <b>publiserer</b> annonser vil de dukke opp her</p>
        <p>
          Velg fra listene til venstre og trykk på "Publiser
          <FontAwesomeIcon :icon="['fal', 'angle-double-right']" size="lg" /> knappen"
        </p>
      </template>
    </div>

    <ButtonComponent
      v-if="value.length && ['unpublished'].includes(state)"
      theme="secondary"
      data-action="preview"
      class="finn-stage__action"
      v-tooltip="{ content: $t('preview'), placement: 'right', delay: 0, offet: 20 }"
      :icon="['fal', 'angle-right']"
      :disabled="!selected.length || selected.some(s => s.canPreview === false)"
      @click="$emit('publish', selected, { preview: true })"
    />
    <ButtonComponent
      v-if="value.length && ['unpublished', 'preview'].includes(state)"
      theme="success"
      data-action="publish"
      class="finn-stage__action"
      v-tooltip="{
        content: `Publiser ${state === 'unpublished' ? '(hopp over forhåndsvisning)' : ''}`,
        placement: 'right',
        delay: 0,
        offet: 20,
      }"
      :icon="['fal', 'angle-double-right']"
      :disabled="!selected.length"
      @click="$emit('publish', selected)"
    />
    <ButtonComponent
      v-if="value.length && ['preview', 'published'].includes(state)"
      theme="error"
      data-action="unpublish"
      class="finn-stage__action"
      v-tooltip="{ content: 'Avpubliser', placement: 'right', delay: 0, offet: 20 }"
      :icon="['fal', 'angle-left']"
      :disabled="!selected.length"
      @click="$emit('remove', selected)"
    />
  </Card>
</template>

<script>
import Card from '@kvass/card'
import DataTable from '@/components/DataTable'
import { ButtonComponent } from 'vue-elder-button'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

export default {
  props: {
    value: Array,
    state: String,
  },
  watch: {
    value() {
      this.selected = []
    },
  },
  data() {
    return {
      selected: [],
    }
  },
  computed: {
    projectAds() {
      return ['ESTATE_PROJECT', 'ESTATE_PROJECT_LEISURE']
    },

    hideFirst() {
      return this.projectAds.includes(this.value[0]?.type)
    },
    label() {
      if (this.state === 'unpublished') return 'Ikke publisert'
      if (this.state === 'preview') return 'Forhåndsvisning'
      if (this.state === 'published') return 'Publisert'
    },
  },
  methods: {
    isDisabled(item) {
      if (this.projectAds.includes(item?.type)) return true
      if (item?.url && !item?.preview) return false
      return Boolean(item?.errors?.length)
    },
    getTooltip(item) {
      const base = {
        offset: 20,
        delay: 50,
      }

      const projectText =
        'Denne annonsen blir generert automatisk sammen med enhetsannonsene og kan derfor ikke publiseres seperat.'
      const isProject = this.projectAds.includes(item?.type)

      if (item.errors?.length)
        return {
          offset: 0,
          delay: 50,
          autoHide: false,
          defaultHideOnTargetClick: false,
          content: `<ul>${item.errors.map(e => `<li>${e}</li>`).join('')}</ul>`,
          classes: ['finn-stage__tooltip', 'finn-stage__tooltip--error'],
        }

      if (item.canPreview === false && this.state === 'unpublished') {
        let previewText =
          'Denne annonsen har vært publisert tidligere og kan derfor ikke forhåndsvises på nytt. Den vil derfor bli publisert uten forhåndsvisning.'

        if (isProject) previewText = previewText.concat('<br><br>', projectText)

        return {
          ...base,
          content: previewText,
          classes: ['finn-stage__tooltip', 'finn-stage__tooltip--warning'],
        }
      }

      if (isProject)
        return {
          ...base,
          content: projectText,
          classes: ['finn-stage__tooltip'],
        }
    },
    getItemState(item) {
      if (item?.url) return { is: 'a', target: '_blank', href: item.url }
      return { is: 'div' }
    },
    getItemIcon(item) {
      if (item.errors?.length) return { icon: ['fas', 'exclamation-triangle'], class: 'finn-stage__icon-error' }
      if (item.canPreview === false && this.state === 'unpublished')
        return { icon: ['fas', 'exclamation-triangle'], class: 'finn-stage__icon-warning' }
      if (this.projectAds.includes(item?.type)) return { icon: ['fas', 'info-circle'], size: 'sm' }
    },
  },
  components: {
    Card,
    DataTable,
    FontAwesomeIcon,
    ButtonComponent,
  },
}
</script>

<style lang="scss">
.finn-stage {
  min-width: 300px;
  min-height: 300px;
  background-color: rgba(black, 0.025);
  border-radius: var(--border-radius-lg, 10px);

  &--unpublished .kvass-card__title:after {
    background-color: var(--error);
    border-color: css-darken('error', 5%);
  }
  &--preview .kvass-card__title:after {
    background-color: var(--primary);
    border-color: css-darken('primary', 5%);
  }
  &--published .kvass-card__title:after {
    background-color: var(--success);
    border-color: css-darken('success', 5%);
  }

  &__placeholder {
    max-width: 175px;
    margin: 4em auto;
    opacity: 0.6;
    font-size: 0.8em;
    text-align: center;
  }

  .kvass-card__title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.5rem;

    &:after {
      content: '';
      display: block;

      width: 12px;
      height: 12px;
      margin-left: 10px;
      border-radius: 50%;
      border-width: 2px;
      border-style: solid;
    }
  }

  .kvass-card__content {
    position: relative;
  }

  &__tooltip {
    ul {
      margin: 0;
      padding: 0 1em 0 2em;
    }

    .tooltip-inner {
      padding: 1em;
      max-width: 300px;
    }

    &--warning {
      .tooltip-inner {
        color: #000;
        background-color: css-lighten('warning', 5%);
      }
      .tooltip-arrow {
        color: #000;
        border-color: css-lighten('warning', 5%);
      }
    }

    &--error {
      --color: #{css-darken('error', 22%)};

      a {
        color: inherit;
      }

      .tooltip-inner {
        background-color: var(--color);
      }
      .tooltip-arrow {
        border-color: var(--color);
      }
    }
  }

  &__item {
    display: flex;
    gap: 1.5rem;
    flex-grow: 1;

    justify-content: space-between;
    align-items: center;

    a.finn-stage__item-content {
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }

    svg {
      margin-left: 0.5rem;
    }
  }

  &__icon {
    &-error {
      color: var(--error);
    }
    &-warning {
      color: var(--warning);
    }
  }

  &__action {
    $offset-y: 120px;
    $offset-x: 27px;
    $size: 40px;
    $gap: 5px;

    width: $size;
    height: $size;

    border-radius: 12px;

    display: flex;
    justify-content: center;
    align-items: center;

    position: absolute;

    transition: all 100ms ease;

    .elder-button__icon {
      padding: 0;
    }

    &:disabled {
      pointer-events: unset;
      cursor: not-allowed;
      border-color: transparent;
      background-color: darken(white, 7%);
    }

    &[data-action='unpublish'] {
      left: $offset-x;
      top: $offset-y;

      translate: -100% 0;
    }
    &[data-action='preview'] {
      right: $offset-x;
      top: $offset-y;

      translate: 100% 0;
    }
    &[data-action='publish'] {
      right: $offset-x;
      top: $offset-y + $size + $gap;

      translate: 100% 0;

      .finn-stage--preview & {
        top: $offset-y;
      }
    }
  }

  &__item-actions {
    display: flex;
    gap: 0.25rem;

    font-size: 0.7em;
    letter-spacing: 0.5px;

    .elder-button {
      --vue-elder-button-padding-y: 2px;
      --vue-elder-button-padding-x: 6px;
    }
  }

  .data-table {
    &__cell {
      padding: 0.5rem 0 0.5rem 0.5rem;
    }
    &__row {
      background-color: transparent !important;
    }
    &__footer {
      display: none;
    }

    &:not(.data-table--no-header) .data-table__row:first-child {
      font-size: 1em;
      font-weight: normal;
      color: rgba(black, 0.4);
    }
  }

  &--hide-first {
    .data-table__row:nth-child(2) {
      .data-table__cell-select {
        visibility: hidden;
      }
    }
  }
}
</style>
