<template>
  <Loader
    :value="loading"
    theme="overlay"
    :delay="0"
    message="Kommuniserer med Finn, dette kan ta opp til flere minutter..."
  >
    <Card v-bind="$attrs" v-on="$listeners" theme="flat" class="finn-list">
      <template #default>
        <Stage :value="unpublished" state="unpublished" @publish="(...args) => publish(...args)"></Stage>
        <Stage
          :value="preview"
          state="preview"
          @publish="(...args) => publish(...args)"
          @remove="(...args) => remove(...args)"
        ></Stage>
        <Stage :value="published" state="published" @remove="(...args) => remove(...args)"></Stage>
      </template>
    </Card>
  </Loader>
</template>

<script>
import Card from '@kvass/card'
import DataTable from '@/components/DataTable'
import { ButtonComponent } from 'vue-elder-button'
import { DropdownComponent } from 'vue-elder-dropdown'
import { AlertComponent as Alert } from 'vue-elder-alert'
import Radio from '@kvass/radio'
import { LoaderComponent as Loader } from 'vue-elder-loader'
import Stage from './Stage'

export default {
  inject: ['account', 'token', 'integration', 'user'],
  props: {
    value: Array,
    category: String,
    initialData: Object,
  },

  watch: {
    value: {
      handler: 'reset',
      immidiate: true,
    },
  },

  data() {
    return {
      data: this.initialData,
      loading: false,
    }
  },
  computed: {
    published() {
      return this.value.filter(({ id }) => {
        let ad = this.data.ads[id]
        return !ad?.canPreview && ad?.url
      })
    },

    preview() {
      return this.value.filter(({ id }) => this.data.ads[id]?.canPreview)
    },

    unpublished() {
      return this.value.filter(({ id }) => !this.data.ads[id]?.url)
    },
  },
  methods: {
    async isPublishing(interval = 5000) {
      this.loading = true

      const data = await fetch(`${this.account}/api/integration/${this.integration}/callbacks/is-publishing`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${this.token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ user: this.user }),
      })
        .then(res => {
          if (res.status !== 200) return true
          return res.json()
        })
        .catch(_ => false)

      if (data === true) return setTimeout(() => this.isPublishing(interval), interval)

      this.$emit('update', data)
      this.loading = false
    },

    reset() {
      this.data = this.initialData
    },

    async publish(residentials, options = {}) {
      if (!residentials.length) return

      const { preview = false } = options

      this.loading = true

      await fetch(`${this.account}/api/integration/${this.integration}/callbacks/publish`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${this.token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ residentials, preview, user: this.user }),
      }).catch(err => console.error(err))

      this.isPublishing()
      this.reset()
    },
    async remove(residentials) {
      if (!residentials.length) return

      this.loading = true

      await fetch(`${this.account}/api/integration/${this.integration}/callbacks/remove`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${this.token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ residentials }),
      }).catch(err => console.error(err))

      this.isPublishing()
      this.reset()
    },
  },

  created() {
    if (this.initialData.publishing.length) return this.isPublishing()
  },

  components: {
    Card,
    ButtonComponent,
    DataTable,
    DropdownComponent,
    Radio,
    Alert,
    Loader,
    Stage,
  },
}
</script>

<style lang="scss">
.finn-list {
  &__settings {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2rem;
    padding: 1.1em;

    border: 1px solid lighten($grey, 4%);

    .elder-dropdown {
      z-index: 1;
    }

    &-label {
      margin-bottom: 0.5em;
      font-size: 1.1em;
    }

    .kvass-radio {
      padding: 1.1em;
      border: 1px solid lighten($grey, 4%);
    }
  }

  & > .kvass-card__content {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
    padding: 0;
  }
}
</style>
