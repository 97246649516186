var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Loader',{attrs:{"value":_vm.loading,"theme":"overlay","delay":0,"message":"Kommuniserer med Finn, dette kan ta opp til flere minutter..."}},[_c('Card',_vm._g(_vm._b({staticClass:"finn-list",attrs:{"theme":"flat"},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('Stage',{attrs:{"value":_vm.unpublished,"state":"unpublished"},on:{"publish":function () {
	var args = [], len = arguments.length;
	while ( len-- ) args[ len ] = arguments[ len ];

	return _vm.publish.apply(void 0, args);
}}}),_c('Stage',{attrs:{"value":_vm.preview,"state":"preview"},on:{"publish":function () {
	var args = [], len = arguments.length;
	while ( len-- ) args[ len ] = arguments[ len ];

	return _vm.publish.apply(void 0, args);
},"remove":function () {
	var args = [], len = arguments.length;
	while ( len-- ) args[ len ] = arguments[ len ];

	return _vm.remove.apply(void 0, args);
}}}),_c('Stage',{attrs:{"value":_vm.published,"state":"published"},on:{"remove":function () {
	var args = [], len = arguments.length;
	while ( len-- ) args[ len ] = arguments[ len ];

	return _vm.remove.apply(void 0, args);
}}})]},proxy:true}])},'Card',_vm.$attrs,false),_vm.$listeners))],1)}
var staticRenderFns = []

export { render, staticRenderFns }