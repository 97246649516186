<template>
  <div class="integration-finn view">
    <div class="row middle g-1 mb-4">
      <a v-if="returnUrl" :href="returnUrl">
        <FontAwesomeIcon :icon="['fal', 'angle-left']" />
        Gå tilbake
      </a>
      <img :src="`${account}/api/integration/preset/finn/assets/logo.svg`" alt="Finn" class="integration-finn__logo" />
    </div>

    <Loader :value="promise" class="integration-finn__loader">
      <Tabs
        v-if="category"
        v-model="category"
        force-header
        :items="categories"
        theme="border"
        class="integration-finn__tabs"
      >
        <template v-slot:[category]>
          <Residentials :value="item" :category="category" :initialData="data" @update="onFetchSuccess"></Residentials>
        </template>
      </Tabs>

      <div v-else>
        {{ $t('somethingWentWrong') }}
      </div>
    </Loader>
  </div>
</template>

<script>
import Tabs from '@/components/Tabs'
import Residentials from '@/components/Finn/Residentials'
import { LoaderComponent as Loader } from 'vue-elder-loader'
import { AlertComponent as Alert } from 'vue-elder-alert'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

export default {
  props: {
    integration: String,
    account: String,
    token: String,
    user: String,
    returnUrl: String,
  },
  provide() {
    return this.$props
  },

  data() {
    return {
      residentials: [],
      category: null,
      data: null,
      promise: null,
    }
  },
  computed: {
    categories() {
      return [...new Map(this.residentials.map(({ group }) => [group.id, group])).values()].sort(
        (a, b) => a.order - b.order,
      )
    },

    item() {
      return this.residentials.filter(({ group }) => group.id === this.category)
    },
  },

  methods: {
    fetch() {
      this.promise = fetch(`${this.account}/api/integration/${this.integration}/callbacks/residentials`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.token}`,
        },
        body: JSON.stringify({ user: this.user }),
      })
        .then(res => res.json())
        .then(data => this.onFetchSuccess(data))
        .catch(err => console.error(err))
    },
    onFetchSuccess(input) {
      const { residentials, data } = input

      this.residentials = residentials
      this.data = data

      if (!this.category)
        this.category =
          this.categories.find(cat => this.residentials.find(r => r.group.id === cat.id))?.id ||
          this.residentials[0].group.id
    },
  },
  components: {
    Tabs,
    Residentials,
    Loader,
    Alert,
    FontAwesomeIcon,
  },
  created() {
    this.fetch()
  },
}
</script>

<style lang="scss">
.integration-finn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // background-color: $background-grey;

  &__logo {
    max-height: 35px;
  }
}
</style>
