<template>
  <div :class="`tabs tabs--${theme}`">
    <div v-if="showHeader" class="tabs__header">
      <ul>
        <li
          v-for="item in items"
          :key="item.id"
          :class="{
            'tabs__header--active': item.id === value,
            'tabs__header--disabled': item.disabled,
          }"
        >
          <a href="#" @click.prevent="$emit('input', item.id)">
            <slot :name="`${item.id}-label`">{{ item.label }}</slot>
          </a>
        </li>
      </ul>
    </div>
    <div class="tabs__details">
      <slot name="before" />
      <div v-if="active" class="tabs__tab" :class="{ 'tabs__tab--clean': !showHeader }">
        <slot :name="active.id" />
      </div>
      <slot name="after" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: [String, Boolean],
    items: Array,
    forceHeader: Boolean,
    theme: {
      type: String,
      default: 'default',
      enum: ['default', 'border'],
    },
  },
  computed: {
    showHeader() {
      return this.items.filter(({ disabled }) => !disabled).length > 1 || this.forceHeader
    },
    active() {
      return this.items.find(i => i.id === this.value)
    },
  },
}
</script>

<style lang="scss">
.tabs {
  display: flex;
  flex-direction: column;

  &__header {
    -webkit-overflow-scrolling: touch;
    -webkit-touch-callout: none;
    user-select: none;
    align-items: stretch;
    display: flex;
    font-size: 1rem;
    justify-content: space-between;
    max-width: calc(100vw - 4rem);
    white-space: nowrap;

    @media (max-width: 400px) {
      max-width: 100vw;
    }

    ul {
      list-style: none;
      align-items: center;
      border-bottom: 1px solid var(--border-color);
      display: flex;
      flex-grow: 1;
      flex-shrink: 0;
      justify-content: flex-start;
      margin: 0;
      padding: 0;
    }

    a {
      align-items: center;
      border-bottom: 1px solid var(--border-color);
      color: var(--dark);
      display: flex;
      justify-content: center;
      margin-bottom: -1px;
      font-weight: bold;
      padding: 0.5em 1em;
      vertical-align: top;
      text-decoration: none;
      &:hover {
        cursor: pointer !important;
      }
      transition: background 150ms ease, color 150ms ease;
    }

    li {
      display: block;
      margin: 0;
      padding: 0;
    }

    &--disabled {
      display: none !important;
    }

    &--active {
      a {
        border-bottom-color: var(--primary);
        color: var(--primary);

        position: relative;

        &:before {
          content: '';
          height: 1px;
          width: 100%;
          display: block;
          position: absolute;
          bottom: 0;
          translate: 0 -1px;
          background: white;
        }
      }
    }
  }

  &__details {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }

  &__tab {
    flex-grow: 1;
  }

  &--default {
    .tabs__tab {
      padding: 1.5rem 0 0;
    }

    .tabs__header li {
      & + li {
        margin-left: 1rem;
      }

      a {
        padding: 0.5em 1em 0.5em 0;
      }
    }
  }

  &--border {
    border-radius: var(--border-radius);

    $tab-background: white;

    .tabs__tab {
      padding: 2rem;
    }

    .tabs__details {
      background-color: $tab-background;
      border: 1px solid var(--border-color);
      border-radius: var(--border-radius-md);
    }

    .tabs__header {
      ul {
        border: none;
      }

      li {
        &:first-child a {
          border-top-left-radius: var(--border-radius-md);
        }
        &:last-child a {
          border-top-right-radius: var(--border-radius-md);
        }

        &:nth-child(n + 2) a {
          border-left: none;
        }
      }

      a {
        padding: 0.5em 1.5em;
        border: 1px solid lighten($grey, 4%);

        @media (max-width: 400px) {
          padding: 1em 1.5em;
        }
      }

      &--active a {
        background-color: $tab-background;
        border-color: var(--border-color);
        border-bottom-color: white;
      }

      & ~ .tabs__details {
        border-top-left-radius: 0;
      }
    }
  }

  &__tab {
    padding: 2rem 1rem 0;

    &--clean,
    &:empty {
      padding: 0 !important;
    }
  }
}
</style>
